import { Button, Table } from 'react-bootstrap';
import "../mycss.css"
import { Sensor } from "./Sensor"

export function WrapperSensores({ sensores, currentPage, prevHandler, nextHandler, mostrarSensor, paginasTotales }) {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>UUID</th>
              <th>Major</th>
              <th>Minor</th>
              <th>TX</th>
              <th>cord. X</th>
              <th>cord. Y</th>
              <th className="anchoCelda">Acciones</th>
            </tr>
          </thead>
          <tbody>
          {sensores.map((sensor) => (
            <Sensor key={`${sensor.major}${sensor.minor}`} sensorAux={sensor} mostrarSensor={mostrarSensor}/>
          ))}
          </tbody>
        </Table>
        <div className="text-center pt-2">
            <Button onClick={prevHandler}>Anterior</Button>
            <span className="px-4">Pagina {currentPage} de {paginasTotales}</span>
            <Button onClick={nextHandler}>Siguiente</Button>
        </div>
      </>
    );
}
