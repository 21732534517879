import React from 'react'
import { useState, useEffect } from 'react'
import { getHorarios, updateVehiculo, auth } from '../../firebase/firebase'
import { ModalEditarVehiculo } from './ModalEditarVehiculo'
import { UseModalEditarVehiculo } from './UseModalEditarVehiculo'
import { sendPasswordResetEmail } from "firebase/auth";
import { Button, Table } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { Input } from 'reactstrap'

export function ModalsEditarVehiculo({vehiculo, onChangeVehiculo}) {
    const navigate = useNavigate();
    const [isOpenModal, openModal, closeModal] = UseModalEditarVehiculo(false)

    const [nombre, setNombre] = useState(vehiculo.nombre)
    const [km, setKm] = useState(vehiculo.km)

    async function onClickAceptar() {
        vehiculo.nombre = nombre
        vehiculo.km = km

        await updateVehiculo(vehiculo)
        
        onChangeVehiculo()
        closeModal()
    }

    return (
        <>
            <Button className="btn-primary btn-block" onClick={openModal}>Editar Vehiculo</Button>
            <ModalEditarVehiculo isOpen={isOpenModal} closeModal={closeModal}>
                <h2><b>Editar Vehiculo</b></h2>
                <hr />
                <h3 className="mb-3">Datos del vehiculo</h3>
                <Table>
                    <tbody>
                    <tr>
                            <td className='p-1'><label>Nombre</label></td>
                            <td className='p-0'><Input defaultValue={nombre} onChange={(e) => {setNombre(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Kilometros</label></td>
                            <td className='p-0'><Input defaultValue={km} onChange={(e) => {setKm(e.target.value)}} /></td>
                        </tr>
                    </tbody>
                </Table>
                <hr />
                <div className='text-right'>
                    <Button className="btn-danger mr-1" onClick={closeModal}>Cancelar</Button>
                    <Button onClick={onClickAceptar}>Aceptar</Button>
                </div>
                
            </ModalEditarVehiculo>
        </>
    )
}
