import React from 'react'
import { useState, useEffect } from 'react'
import { getHorarios, addSensor, auth } from '../../firebase/firebase'
import { ModalRegistrarSensor } from './ModalRegistrarSensor'
import { UseModalRegistrarSensor } from './UseModalRegistrarSensor'
import { sendPasswordResetEmail } from "firebase/auth";
import { Button, Table } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { Input } from 'reactstrap'

export function ModalsRegistrarSensor({onChangeSensores}) {
    const navigate = useNavigate();
    const [isOpenModal, openModal, closeModal] = UseModalRegistrarSensor(false)

    const [uuid, setUuid] = useState("")
    const [major, setMajor] = useState("")
    const [minor, setMinor] = useState("")
    const [x, setX] = useState("")
    const [y, setY] = useState("")
    const [tx, setTx] = useState("")

    async function onClickAceptar() {
        const sensor = {}

        sensor.uuid = uuid
        sensor.major = major
        sensor.minor = minor
        sensor.x = x
        sensor.y = y
        sensor.TX = tx

        sensor.id = `id_${sensor.major}-${sensor.minor}`

        await addSensor(sensor)

        setUuid("")
        setMajor("")
        setMinor("")
        setX("")
        setY("")
        setTx("")
        
        onChangeSensores()
        closeModal()
    }

    return (
        <>
            <Button className="btn-primary" onClick={openModal}>Añadir nuevo sensor</Button>
            <ModalRegistrarSensor isOpen={isOpenModal} closeModal={closeModal}>
                <h2><b>Añadir Nuevo Sensor</b></h2>
                <hr />
                <h3 className="mb-3">Datos del Sensor</h3>
                <Table>
                    <tbody>
                        <tr>
                            <td className='p-1'><label>UUID</label></td>
                            <td className='p-0'><Input defaultValue={uuid} onChange={(e) => {setUuid(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Major</label></td>
                            <td className='p-0'><Input defaultValue={major} onChange={(e) => {setMajor(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Minor</label></td>
                            <td className='p-0'><Input defaultValue={minor} onChange={(e) => {setMinor(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>TX</label></td>
                            <td className='p-0'><Input defaultValue={tx} onChange={(e) => {setTx(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Cord X</label></td>
                            <td className='p-0'><Input defaultValue={x} onChange={(e) => {setX(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Cord Y</label></td>
                            <td className='p-0'><Input defaultValue={y} onChange={(e) => {setY(e.target.value)}} /></td>
                        </tr>
                    </tbody>
                </Table>
                
                <br />
                <div className='text-right'>
                    <Button className="btn-danger mr-1" onClick={closeModal}>Cancelar</Button>
                    <Button onClick={onClickAceptar}>Aceptar</Button>
                </div>
                
            </ModalRegistrarSensor>
        </>
    )
}
