import React from "react";
import { Login } from "./routes/Login";
import { BrowserRouter, Routes, Route, HashRouter, RouterProvider } from "react-router-dom";
import { ControlHorarioAdmin } from "./routes/ControlHorarioAdmin";

function App() {
  return (
      <div className="body">
        <HashRouter>
          <Routes>
            <Route path="/" element={<ControlHorarioAdmin/>}/>
            <Route path="login" element={<Login/>}/>
            <Route path="*" element={<div>Error 404 page not found</div>}/>
          </Routes>
        </HashRouter>
      </div>
  );
}

export default App;