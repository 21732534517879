import React from 'react'
import { useState, useEffect } from 'react'
import { getHorarios, updateTrabajador, auth } from '../../firebase/firebase'
import { ModalEditarTrabajador } from './ModalEditarTrabajador'
import { UseModalEditarTrabajador } from './UseModalEditarTrabajador'
import { sendPasswordResetEmail } from "firebase/auth";
import { Button, Table } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { Input } from 'reactstrap'

export function ModalsEditarTrabajador({trabajador, onChangeTrabajador}) {
    const navigate = useNavigate();
    const [isOpenModal, openModal, closeModal] = UseModalEditarTrabajador(false)

    const [nombre, setNombre] = useState(trabajador.nombre)
    const [apellidos, setApellidos] = useState(trabajador.apellidos)

    const [trabajadorActivo, setTrabajadorActivo] = useState(trabajador.activo)
    const [admin, setAdmin] = useState(trabajador.admin)

    async function onClickAceptar() {
        trabajador.nombre = nombre
        trabajador.apellidos = apellidos
        trabajador.activo = trabajadorActivo
        trabajador.admin = admin

        await updateTrabajador(trabajador)
        
        onChangeTrabajador()
        closeModal()
    }

    function onChangeTrabajadorActivo(e) {
        if (e.target.value === "true") setTrabajadorActivo(false)
        if (e.target.value === "false") setTrabajadorActivo(true)
    }

    function onChangeTrabajadorAdmin(e) {
        if (e.target.value === "true") setAdmin(false)
        if (e.target.value === "false") setAdmin(true)
    }

    return (
        <>
            <style type="text/css">
                {`
                    .btn-blue {
                        background-color: blue;
                        color: white;
                    }
                    .btn-red {
                        background-color: red;
                        color: white;
                    }
                `}
            </style>
            <Button className="btn-primary btn-block" onClick={openModal}>Editar Trabajador</Button>
            <ModalEditarTrabajador isOpen={isOpenModal} closeModal={closeModal}>
                <h2><b>Editar Trabajador</b></h2>
                <hr />
                <h3 className="mb-3">Datos del Trabajador</h3>
                <Table>
                    <tbody>
                        <tr>
                            <td className='p-1'><label>Correo electronico</label></td>
                            <td className='p-0'><Input disabled={true} defaultValue={trabajador.email} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Nombre</label></td>
                            <td className='p-0'><Input defaultValue={nombre} onChange={(e) => {setNombre(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Apellidos</label></td>
                            <td className='p-0'><Input defaultValue={apellidos} onChange={(e) => {setApellidos(e.target.value)}} /></td>
                        </tr>
                    </tbody>
                </Table>
                <hr />
                <h3 className="mb-3">Otras opciones del trabajador</h3>
                <div>
                    <div><input type="checkbox" checked={trabajadorActivo} value={trabajadorActivo} onChange={onChangeTrabajadorActivo}/>&nbsp; Trabajador Activo</div>
                    <div><input type="checkbox" checked={admin} value={admin} onChange={onChangeTrabajadorAdmin}/>&nbsp; Admin</div>
                </div>
                    
                <br />
                <div className='text-right'>
                    <Button className="btn-danger mr-1" onClick={closeModal}>Cancelar</Button>
                    <Button onClick={onClickAceptar}>Aceptar</Button>
                </div>
                
            </ModalEditarTrabajador>
        </>
    )
}
