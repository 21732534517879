import { useState, useEffect } from "react";
import {
  auth,
  getAlertas
} from "../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Input } from "reactstrap";
import { WrapperTrabajadores } from './WrapperTrabajadores';
import { ModalsRegistrarTrabajador } from "./modalRegistrarTrabajador/ModalsRegistrarTrabajador";
import { WrapperAlertas } from "./WrapperAlertas";

export function ListaAlertas({mostrar}) {
  const navigate = useNavigate();
  const [alertas, setAlertas] = useState(null);
  const [tablaAlertas, setTablaAlertas] = useState([]);
  const [alertasPorPaginas, setAlertasPorPaginas] = useState([]);
  const [paginasTotales, setPaginasTotales] = useState(0);
  const [entries, setEntries] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [busqueda, setBusqueda] = useState("")
  const [alerta, setAlerta] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        todasLasAlertas()
      }
      else {
          navigate("/login");
      }
    });
  }, [navigate]);

  async function todasLasAlertas() {
    const alertasAux = await getAlertas();
    setAlertas([...alertasAux]);
    setTablaAlertas([...alertasAux]);
    setAlertasPorPaginas([...alertasAux].splice(0, entries));
    if ([...alertasAux].length%entries === 0) setPaginasTotales([...alertasAux].length/entries);
    else setPaginasTotales(parseInt([...alertasAux].length/entries)+1);
  }

  function prevHandler() {
    const prevPage = currentPage - 1
    
    if (prevPage < 1) return;

    const firstIndex = (prevPage - 1) * entries;

    setAlertasPorPaginas([...alertas].splice(firstIndex, entries))
    setCurrentPage(prevPage)
    // console.log(prevPage);
  }

  function nextHandler() {
    const totalElementos = alertas.length;
    const nextPage = currentPage + 1;
    const firstIndex = (nextPage - 1) * entries;

    if (firstIndex >= totalElementos) return;

    setAlertasPorPaginas([...alertas].splice(firstIndex, entries))
    setCurrentPage(nextPage)
    // console.log(nextPage);
  }

  function onChangeEntries(e) {
    setEntries(e.target.value)
    setCurrentPage(1)
    if ([...alertas].length%e.target.value === 0) setPaginasTotales([...alertas].length/e.target.value);
    else setPaginasTotales(parseInt([...alertas].length/e.target.value)+1);
    
    setAlertasPorPaginas([...alertas].splice(0, e.target.value));
  }

  function handleBusqueda(e) {
    // console.log(e.target.value)
    setBusqueda(e.target.value)
    filtrar(e.target.value)
  }

  function filtrar (terminoBusqueda) {
    let resultadosBusqueda = tablaAlertas.filter((alerta)=>{
      if(alerta.trabajador.toLowerCase().includes(terminoBusqueda.toLowerCase()) || alerta.distancia.toLowerCase().includes(terminoBusqueda.toLowerCase()) || alerta.vehiculo.toLowerCase().includes(terminoBusqueda.toLowerCase()) || alerta.fecha.toLowerCase().includes(terminoBusqueda.toLowerCase()) || alerta.hora.toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return alerta
      }
    });
    setCurrentPage(1)
    if (resultadosBusqueda.length%entries === 0) setPaginasTotales(resultadosBusqueda.length/entries);
    else setPaginasTotales(parseInt(resultadosBusqueda.length/entries)+1);
    
    setAlertas([...resultadosBusqueda])
    setAlertasPorPaginas(resultadosBusqueda.splice(0, entries));
    
    // console.log(currentPage);
  }

  function mostrarAlerta(alertaAux) {
    setAlerta(alertaAux)
  }

  if (mostrar === true && alertas !== null) {
    return (
        <>
            <hr />
            <div className="mt-4 mb-4 w-100">
                
                <span className="float-right mb-3">
                <Button variant="transparent">
                Mostrar&nbsp;
                <select className="text-center" onChange={onChangeEntries}>
                    <option key="10" value="10">10</option>
                    <option key="25" value="25">25</option>
                    <option key="50" value="50">50</option>
                    <option key="100" value="100">100</option>
                </select>&nbsp;
                entradas
                </Button>
                <Input className="float-right w-50" value={busqueda} onChange={handleBusqueda} placeholder="Buscar alerta..."/>
                </span>
                <span className="float-center mb-3 h3">Alertas</span>
            </div>
            <WrapperAlertas alertas={alertasPorPaginas} currentPage={currentPage} paginasTotales={paginasTotales} prevHandler={prevHandler} nextHandler={nextHandler} mostrarAlerta={mostrarAlerta}/>
            <br /><br /><br /><br /><br /><br /><br />
        </>
    );
  } else return(<></>)
}