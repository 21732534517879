// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  setDoc,
  deleteDoc,
  orderBy
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const auth2 = getAuth(initializeApp(firebaseConfig, "Secondary"));
export const db = getFirestore(app);
export const storage = getStorage(app);

export async function userExists(uid) {
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  // console.log(res);
  return res.exists();
}

export async function getUser(uid) {
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function getSensores() {
  const sensores = [];
  try {
    const q = query(collection(db, "sensores") /* , where("admin", "==", false)); */ )
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      sensores.push(doc.data())
    })

    return sensores;

  } catch (error) {
    // console.error(error)
  }
}

export async function updateSensor(sensor) {
  try {
    // console.log(user)
    const res = await updateDoc(
      doc(db, "sensores", sensor.id),
      sensor
    );
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear trabajadores");
    // console.error(e);
  }
}

export async function getSensor(sensor) {
  const docRef = doc(db, "sensores", sensor.id);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function addSensor(sensor) {
  try {
    const usersRef = collection(db, "sensores");
    await setDoc(doc(usersRef, sensor.id), sensor);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function getTrabajadores() {
  const trabajadores = [];
  try {
    const q = query(collection(db, "users"));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      trabajadores.push(doc.data())
    })

    return trabajadores;

  } catch (error) {
    // console.error(error)
  }
}

export async function getTrabajador(trabajador) {
  const docRef = doc(db, "users", trabajador.uuid);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function updateTrabajador(user) {
  try {
    // console.log(user)
    const res = await updateDoc(
      doc(db, "users", user.uuid),
      user
    );
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear trabajadores");
    // console.error(e);
  }
}

export async function addTrabajador(user) {
  try {
    const usersRef = collection(db, "users");
    await setDoc(doc(usersRef, user.uuid), user);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function getVehiculos() {
  const vehiculos = [];
  try {
    const q = query(collection(db, "vehiculos"));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      vehiculos.push(doc.data())
    })

    return vehiculos;

  } catch (error) {
    // console.error(error)
  }
}

export async function getVehiculo(vehiculo) {
  const docRef = doc(db, "vehiculos", vehiculo.id);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function updateVehiculo(vehiculo) {
  try {
    // console.log(user)
    const res = await updateDoc(
      doc(db, "vehiculos", vehiculo.id),
      vehiculo
    );
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear trabajadores");
    // console.error(e);
  }
}

export async function addVehiculo(vehiculo) {
  try {
    const usersRef = collection(db, "vehiculos");
    await setDoc(doc(usersRef, vehiculo.id), vehiculo);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function getAlertas() {
  const alertas = [];
  try {
    const q = query(collection(db, "alertas"), orderBy('id', 'desc'));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      alertas.push(doc.data())
    })

    return alertas;

  } catch (error) {
    // console.error(error)
  }
}

export async function getAlerta(alerta) {
  const docRef = doc(db, "alertas", alerta.id);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}