import React from 'react'
import { signOut } from "firebase/auth";
import { getHorarios, updateTrabajador, auth, addHorario, updateHorario, getDiasFestivosPorAñoyMes } from '../firebase/firebase'
import { useNavigate } from "react-router-dom";
import { Button, Navbar, Table } from "react-bootstrap";
import "../mycss.css"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap'
import { useState, useEffect } from "react";
import settings from "../img/settings.png"
import logoblanco from "../img/logo_fondo_blanco.png"

export default function Header() {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false)

  function logout() {
    signOut(auth)
      .then(() => {
        // console.log("OUT");
        navigate("/login");
      })
      .catch((error) => {
        // console.error(error);
        // console.log("error logout");
      });
  }

  function onChangeDropdown() {
    setDropdown(!dropdown);
  }

    return (
      <Navbar className="bgpurple py-3">
        <a href="./">
            <img src={logoblanco}
                    width="50"
                    height="50"
                    className="mr-3 rounded"
                    />
        </a>
        <a href="./">
            <h2 className="colorwhite mr-4 mb-1">Perimeter Indoor</h2>
        </a>
        <span className="mr-auto"></span>
        <div className='navbar-right'>
          <Dropdown isOpen={dropdown} toggle={onChangeDropdown}>
            <DropdownToggle className="bg-info" caret>
            <img src={settings}
                  width="20"
                  height="20"
                />
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem onClick={logout}>Cerrar sesion</DropdownItem>
            </DropdownMenu>
          </Dropdown>   
        </div>
      </Navbar>
    );
}
