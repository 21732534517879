import { Button, Table } from 'react-bootstrap';
import "../mycss.css"
import { Vehiculo } from "./Vehiculo"

export function WrapperVehiculos({ vehiculos, currentPage, prevHandler, nextHandler, mostrarVehiculo, paginasTotales }) {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Kilometros</th>
              <th className="anchoCelda">Acciones</th>
            </tr>
          </thead>
          <tbody>
          {vehiculos.map((vehiculo) => (
            <Vehiculo key={`${vehiculo.id}`} vehiculoAux={vehiculo} mostrarVehiculo={mostrarVehiculo}/>
          ))}
          </tbody>
        </Table>
        <div className="text-center pt-2">
            <Button onClick={prevHandler}>Anterior</Button>
            <span className="px-4">Pagina {currentPage} de {paginasTotales}</span>
            <Button onClick={nextHandler}>Siguiente</Button>
        </div>
      </>
    );
}
