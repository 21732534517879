import { useState, useEffect } from "react";
import {
  auth,
  getSensores
} from "../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Input } from "reactstrap";
import { WrapperSensores } from './WrapperSensores';
import { ModalsRegistrarSensor } from "./modalRegistrarSensor/ModalsRegistrarSensor";

export function ListaSensores({mostrar}) {
  const navigate = useNavigate();
  const [sensores, setSensores] = useState(null);
  const [tablaSensores, setTablaSensores] = useState([]);
  const [sensoresPorPaginas, setSensoresPorPaginas] = useState([]);
  const [paginasTotales, setPaginasTotales] = useState(0);
  const [entries, setEntries] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [busqueda, setBusqueda] = useState("")
  const [sensor, setSensor] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            todosLosSensores()
        }
        else {
            navigate("/login");
        }
    });
  }, [navigate]);

  async function todosLosSensores() {
    const sensoresAux = await getSensores();
    setSensores([...sensoresAux]);
    setTablaSensores([...sensoresAux]);
    setSensoresPorPaginas([...sensoresAux].splice(0, entries));
    if ([...sensoresAux].length%entries === 0) setPaginasTotales([...sensoresAux].length/entries);
    else setPaginasTotales(parseInt([...sensoresAux].length/entries)+1);
  }

  function prevHandler() {
    const prevPage = currentPage - 1
    
    if (prevPage < 1) return;

    const firstIndex = (prevPage - 1) * entries;

    setSensoresPorPaginas([...sensores].splice(firstIndex, entries))
    setCurrentPage(prevPage)
    // console.log(prevPage);
  }

  function nextHandler() {
    const totalElementos = sensores.length;
    const nextPage = currentPage + 1;
    const firstIndex = (nextPage - 1) * entries;

    if (firstIndex >= totalElementos) return;

    setSensoresPorPaginas([...sensores].splice(firstIndex, entries))
    setCurrentPage(nextPage)
    // console.log(nextPage);
  }

  function onChangeEntries(e) {
    setEntries(e.target.value)
    setCurrentPage(1)
    if ([...sensores].length%e.target.value === 0) setPaginasTotales([...sensores].length/e.target.value);
    else setPaginasTotales(parseInt([...sensores].length/e.target.value)+1);
    
    setSensoresPorPaginas([...sensores].splice(0, e.target.value));
  }

  function handleBusqueda(e) {
    // console.log(e.target.value)
    setBusqueda(e.target.value)
    filtrar(e.target.value)
  }

  function filtrar (terminoBusqueda) {
    let resultadosBusqueda = tablaSensores.filter((sensor)=>{
      if(sensor.uuid.toLowerCase().includes(terminoBusqueda.toLowerCase()) || sensor.major.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) || sensor.minor.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return sensor
      }
    });
    setCurrentPage(1)
    if (resultadosBusqueda.length%entries === 0) setPaginasTotales(resultadosBusqueda.length/entries);
    else setPaginasTotales(parseInt(resultadosBusqueda.length/entries)+1);
    
    setSensores([...resultadosBusqueda])
    setSensoresPorPaginas(resultadosBusqueda.splice(0, entries));
    
    // console.log(currentPage);
  }

  function mostrarSensor(sensorAux) {
    setSensor(sensorAux)
    // setBRegistros(false)
    // setBRegistrosAño(false)
    // setRegistros([])
    // setRegistrosAño([])
  }

  if (mostrar === true && sensores !== null) {
    return (
        <>
            <hr />
            <div className="mt-4 mb-4 w-100">
                <ModalsRegistrarSensor onChangeSensores={todosLosSensores}/>
                
                <span className="float-right mb-3">
                <Button variant="transparent">
                Mostrar&nbsp;
                <select className="text-center" onChange={onChangeEntries}>
                    <option key="10" value="10">10</option>
                    <option key="25" value="25">25</option>
                    <option key="50" value="50">50</option>
                    <option key="100" value="100">100</option>
                </select>&nbsp;
                entradas
                </Button>
                <Input className="float-right w-50" value={busqueda} onChange={handleBusqueda} placeholder="Buscar sensor..."/>
                </span>
                <br />
                <br />
                <span className="float-center mb-3 h3">Sensores</span>
            </div>
            <WrapperSensores sensores={sensoresPorPaginas} currentPage={currentPage} paginasTotales={paginasTotales} prevHandler={prevHandler} nextHandler={nextHandler} mostrarSensor={mostrarSensor}/>
            <br /><br /><br /><br /><br /><br /><br />
        </>
    );
  } else return(<></>)
}