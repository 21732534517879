import React from 'react'
import { useState, useEffect } from 'react'
import { getHorarios, addVehiculo, auth, auth2 } from '../../firebase/firebase'
import { ModalRegistrarVehiculo } from './ModalRegistrarVehiculo'
import { UseModalRegistrarVehiculo } from './UseModalRegistrarVehiculo'
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { Button, Table } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { Input } from 'reactstrap'

export function ModalsRegistrarVehiculo({onChangeVehiculos}) {
    const navigate = useNavigate();
    const [isOpenModal, openModal, closeModal] = UseModalRegistrarVehiculo(false)

    const [nombre, setNombre] = useState("")
    const [km, setKm] = useState("")

    async function onClickAceptar() {
        const vehiculo = {}

        vehiculo.nombre = nombre
        vehiculo.km = km
        vehiculo.id = `${nombre}_${parseInt(getRandomNumber())}`

        await addVehiculo(vehiculo)

        setNombre("")
        setKm("")

        onChangeVehiculos()
        closeModal()
    }

    function getRandomNumber() {
        return Math.random() * (1000000 - 1) + 1;
    }

    return (
        <>
            <Button className="btn-primary" onClick={openModal}>Añadir nuevo vehiculo</Button>
            <ModalRegistrarVehiculo isOpen={isOpenModal} closeModal={closeModal}>
                <h2><b>Añadir Nuevo vehiculo</b></h2>
                <hr />
                <h3 className="mb-3">Datos del vehiculo</h3>
                <Table>
                    <tbody>
                    <tr>
                            <td className='p-1'><label>Nombre</label></td>
                            <td className='p-0'><Input defaultValue={nombre} onChange={(e) => {setNombre(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>km</label></td>
                            <td className='p-0'><Input defaultValue={km} onChange={(e) => {setKm(e.target.value)}} /></td>
                        </tr>
                    </tbody>
                </Table>
                <hr />
                
                <div className='text-right'>
                    <Button className="btn-danger mr-1" onClick={closeModal}>Cancelar</Button>
                    <Button onClick={onClickAceptar}>Aceptar</Button>
                </div>
                
            </ModalRegistrarVehiculo>
        </>
    )
}
