import { useState, useEffect } from 'react'
import { getTrabajador, getVehiculo } from "../firebase/firebase";
import { Button } from 'react-bootstrap';
import "../mycss.css"
import { ModalsEditarVehiculo } from './modalEditarVehiculo/ModalsEditarVehiculo';

export function Vehiculo({vehiculoAux}) {
  const [vehiculo, setVehiculo] = useState(vehiculoAux)

  async function onChangeVehiculo() {
    const vehiculoAux = await getVehiculo(vehiculo)
    setVehiculo(vehiculoAux)
  }

  return (
  <>
    <tr className="alturaColumna p-0">
      <td className="p-3">{vehiculo.nombre}</td>
      <td className="p-3">{vehiculo.km} Km</td>
      <td className="anchoCelda p-1">
        {/* <div className='row'> */}
          <div><ModalsEditarVehiculo vehiculo={vehiculo} onChangeVehiculo={onChangeVehiculo} /></div>
          {/* <div className='col'><ModalsBajaVacaciones trabajador={trabajador}/></div> */}
        {/* </div>
        <div className='row'> */}
          {/* <div classNamke='col'><ModalsEditarTrabajador trabajador={trabajador}/></div> */}
          {/* <div className='col'><Button className='btn-danger btn-block alturaBotones p-0' onClick={onClickDesactivar}>Desactivar</Button></div> */}
        {/* </div> */}
      </td>
    </tr>
  </>
  );
}