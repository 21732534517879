import { Button, Table } from 'react-bootstrap';
import "../mycss.css"
import { Alerta } from "./Alerta"

export function WrapperAlertas({ alertas, currentPage, prevHandler, nextHandler, mostrarAlerta, paginasTotales }) {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Distancia</th>
              <th>Trabajador</th>
              <th>Vehiculo</th>
            </tr>
          </thead>
          <tbody>
          {alertas.map((alerta) => (
            <Alerta key={`${alerta.id}`} alertaAux={alerta} mostrarAlerta={mostrarAlerta}/>
          ))}
          </tbody>
        </Table>
        <div className="text-center pt-2">
            <Button onClick={prevHandler}>Anterior</Button>
            <span className="px-4">Pagina {currentPage} de {paginasTotales}</span>
            <Button onClick={nextHandler}>Siguiente</Button>
        </div>
      </>
    );
}
