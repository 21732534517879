import { useState, useEffect } from "react";
import {
  auth,
  getTrabajadores,
  getVehiculos
} from "../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Input } from "reactstrap";
import { WrapperTrabajadores } from './WrapperTrabajadores';
import { ModalsRegistrarVehiculo } from "./modalRegistrarVehiculo/ModalsRegistrarVehiculo";
import { WrapperVehiculos } from "./WrapperVehiculos";

export function ListaVehiculos({mostrar}) {
  const navigate = useNavigate();
  const [vehiculos, setVehiculos] = useState(null);
  const [tablaVehiculos, setTablaVehiculos] = useState([]);
  const [vehiculosPorPaginas, setVehiculosPorPaginas] = useState([]);
  const [paginasTotales, setPaginasTotales] = useState(0);
  const [entries, setEntries] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [busqueda, setBusqueda] = useState("")
  const [vehiculo, setVehiculo] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        todosLosVehiculos()
      }
      else {
        navigate("/login");
      }
    });
  }, [navigate]);

  async function todosLosVehiculos() {
    const vehiculosAux = await getVehiculos();
    setVehiculos([...vehiculosAux]);
    setTablaVehiculos([...vehiculosAux]);
    setVehiculosPorPaginas([...vehiculosAux].splice(0, entries));
    if ([...vehiculosAux].length%entries === 0) setPaginasTotales([...vehiculosAux].length/entries);
    else setPaginasTotales(parseInt([...vehiculosAux].length/entries)+1);
  }

  function prevHandler() {
    const prevPage = currentPage - 1
    
    if (prevPage < 1) return;

    const firstIndex = (prevPage - 1) * entries;

    setVehiculosPorPaginas([...vehiculos].splice(firstIndex, entries))
    setCurrentPage(prevPage)
    // console.log(prevPage);
  }

  function nextHandler() {
    const totalElementos = vehiculos.length;
    const nextPage = currentPage + 1;
    const firstIndex = (nextPage - 1) * entries;

    if (firstIndex >= totalElementos) return;

    setVehiculosPorPaginas([...vehiculos].splice(firstIndex, entries))
    setCurrentPage(nextPage)
    // console.log(nextPage);
  }

  function onChangeEntries(e) {
    setEntries(e.target.value)
    setCurrentPage(1)
    if ([...vehiculos].length%e.target.value === 0) setPaginasTotales([...vehiculos].length/e.target.value);
    else setPaginasTotales(parseInt([...vehiculos].length/e.target.value)+1);
    
    setVehiculosPorPaginas([...vehiculos].splice(0, e.target.value));
  }

  function handleBusqueda(e) {
    // console.log(e.target.value)
    setBusqueda(e.target.value)
    filtrar(e.target.value)
  }

  function filtrar (terminoBusqueda) {
    let resultadosBusqueda = tablaVehiculos.filter((vehiculo)=>{
      if(vehiculo.nombre.toLowerCase().includes(terminoBusqueda.toLowerCase()) || vehiculo.id.toLowerCase().includes(terminoBusqueda.toLowerCase()) || vehiculo.km.toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return vehiculo
      }
    });
    setCurrentPage(1)
    if (resultadosBusqueda.length%entries === 0) setPaginasTotales(resultadosBusqueda.length/entries);
    else setPaginasTotales(parseInt(resultadosBusqueda.length/entries)+1);
    
    setVehiculos([...resultadosBusqueda])
    setVehiculosPorPaginas(resultadosBusqueda.splice(0, entries));
    
    // console.log(currentPage);
  }

  function mostrarVehiculo(vehiculoAux) {
    setVehiculos(vehiculoAux)
  }

  if (mostrar === true && vehiculos !== null) {
    return (
        <>
            <hr />
            <div className="mt-4 mb-4 w-100">
                <ModalsRegistrarVehiculo onChangeVehiculos={todosLosVehiculos} />
                
                <span className="float-right mb-3">
                <Button variant="transparent">
                Mostrar&nbsp;
                <select className="text-center" onChange={onChangeEntries}>
                    <option key="10" value="10">10</option>
                    <option key="25" value="25">25</option>
                    <option key="50" value="50">50</option>
                    <option key="100" value="100">100</option>
                </select>&nbsp;
                entradas
                </Button>
                <Input className="float-right w-50" value={busqueda} onChange={handleBusqueda} placeholder="Buscar vehiculo..."/>
                </span>
                <br />
                <br />
                <span className="float-center mb-3 h3">Vehiculos</span>
            </div>
            <WrapperVehiculos vehiculos={vehiculosPorPaginas} currentPage={currentPage} paginasTotales={paginasTotales} prevHandler={prevHandler} nextHandler={nextHandler} mostrarVehiculo={mostrarVehiculo}/>
            <br /><br /><br /><br /><br /><br /><br />
        </>
    );
  } else return(<></>)
}