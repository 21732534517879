import { Button } from "react-bootstrap"
import "../../mycss.css"

export const ModalRegistrarVehiculo = ({children, isOpen, closeModal}) => {

  const handleModalContainerClick = e => e.stopPropagation()
  
  return (
    <article className={`modal-editar-sensor ${isOpen && "is-open"}`} onClick={closeModal}>
        <div className="modal-container-editar-sensor" onClick={handleModalContainerClick}>
            <Button variant="transparent" className="modal-close-editar-sensor" onClick={closeModal}>X</Button>
            {children}
        </div>
    </article>
  )
}