import React from 'react'
import { useState, useEffect } from 'react'
import { getHorarios, addTrabajador, auth, auth2 } from '../../firebase/firebase'
import { ModalRegistrarTrabajador } from './ModalRegistrarTrabajador'
import { UseModalRegistrarTrabajador } from './UseModalRegistrarTrabajador'
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { Button, Table } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { Input } from 'reactstrap'

export function ModalsRegistrarTrabajador({onChangeTrabajadores}) {
    const navigate = useNavigate();
    const [isOpenModal, openModal, closeModal] = UseModalRegistrarTrabajador(false)

    const [email, setEmail] = useState("")
    const [nombre, setNombre] = useState("")
    const [apellidos, setApellidos] = useState("")
    const [trabajadorActivo, setTrabajadorActivo] = useState(true)
    const [admin, setAdmin] = useState(true)
    const [mensajeError, setMensajeError] = useState("")

    function singUp() {
        //register user in auth
        createUserWithEmailAndPassword(auth2, email, "1234567890")
          .then((userCredential) => {
            setMensajeError("")
            addUserBBDD(userCredential.user);
          })
          .catch((error) => {
            // console.log(error.code)
            // console.log(error.message)
            // console.log("error al registrar");
            setMensajeError("Error al registrar usuario")
          });
      }

    async function addUserBBDD(user) {
        const trabajador = {}

        trabajador.email = email
        trabajador.nombre = nombre
        trabajador.apellidos = apellidos
        trabajador.activo = trabajadorActivo
        trabajador.admin = admin
        trabajador.uuid = user.uid

        await addTrabajador(trabajador)

        //reset password email
        sendPasswordResetEmail(auth, email)
        .then(() => {
        //   console.log("EMAIL TO "+email)
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        //   console.log(errorCode)
        //   console.log(errorMessage)
          setMensajeError("Error al registrar usuario")
        });
        
        //logout
        signOut(auth2)
        .then(() => {
            setMensajeError("")

            setNombre("")
            setEmail("")
            setApellidos("")
            setTrabajadorActivo(true)
            setAdmin(true)

            onChangeTrabajadores()
            closeModal()
        })
        .catch((error) => {
            // console.error(error);
            // console.log("error logout");
            setMensajeError("Error al registrar usuario")
        });
    }

    function onChangeTrabajadorActivo(e) {
        if (e.target.value === "true") setTrabajadorActivo(false)
        if (e.target.value === "false") setTrabajadorActivo(true)
    }

    function onChangeTrabajadorAdmin(e) {
        if (e.target.value === "true") setAdmin(false)
        if (e.target.value === "false") setAdmin(true)
    }

    return (
        <>
            <Button className="btn-primary" onClick={openModal}>Añadir nuevo trabajador</Button>
            <ModalRegistrarTrabajador isOpen={isOpenModal} closeModal={closeModal}>
                <h2><b>Añadir Nuevo Trabajador</b></h2>
                <hr />
                <h3 className="mb-3">Datos del Trabajador</h3>
                <Table>
                    <tbody>
                        <tr>
                            <td className='p-1'><label>Correo electronico</label></td>
                            <td className='p-0'><Input defaultValue={email} onChange={(e) => {setEmail(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Nombre</label></td>
                            <td className='p-0'><Input defaultValue={nombre} onChange={(e) => {setNombre(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Apellidos</label></td>
                            <td className='p-0'><Input defaultValue={apellidos} onChange={(e) => {setApellidos(e.target.value)}} /></td>
                        </tr>
                    </tbody>
                </Table>
                <hr />
                <h3 className="mb-3">Otras opciones del trabajador</h3>
                <div>
                    <div><input type="checkbox" checked={trabajadorActivo} value={trabajadorActivo} onChange={onChangeTrabajadorActivo}/>&nbsp; Trabajador Activo</div>
                    <div><input type="checkbox" checked={admin} value={admin} onChange={onChangeTrabajadorAdmin}/>&nbsp; Admin</div>
                </div>
                <br />
                <div className='text-right'>
                    <span className='text-danger mr-2'>{mensajeError}</span>
                    <Button className="btn-danger mr-1" onClick={closeModal}>Cancelar</Button>
                    <Button onClick={singUp}>Aceptar</Button>
                </div>
                
            </ModalRegistrarTrabajador>
        </>
    )
}
