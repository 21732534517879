import { onAuthStateChanged, signOut } from "firebase/auth";
import { useState, useContext, useEffect } from "react";
import {
  auth,
  getUser,
} from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
// import ListaTrabajadores from "../components/controlhorarioadmin/ListaTrabajadores";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { ListaSensores } from "../components/ListaSensores";
import { ListaTrabajadores } from "../components/ListaTrabajadores";
import { ListaVehiculos } from "../components/ListaVehiculos";
import { ListaAlertas } from "../components/ListaAlertas";
import { PerimeterIndoor } from "../components/PerimeterIndoor";

export function ControlHorarioAdmin() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [mostrarSensores, setMostrarSensores] = useState(false);
  const [mostrarTrabajadores, setMostrarTrabajadores] = useState(false);
  const [mostrarVehiculos, setMostrarVehiculos] = useState(false);
  const [mostrarAlertas, setMostrarAlertas] = useState(false);
  const [mostrarSobre, setMostrarSobre] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDB = await getUser(user.uid);
        setUser(userDB);

        if (userDB.admin === false) logout()
        if (userDB.activo === false) logout()

      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  function logout() {
    signOut(auth)
      .then(() => {
        // console.log("OUT");
        navigate("/login");
      })
      .catch((error) => {
        // console.error(error);
        // console.log("error logout");
      });

    //console.log("OUT");
    //auth().logout();
    //window.location.href = "login.html";
  }

  function onClickSensores() {
    setMostrarVehiculos(false)
    setMostrarTrabajadores(false)
    setMostrarAlertas(false)
    setMostrarSobre(false)
    setMostrarSensores(true)
  }
  
  function onClickTrabajadores() {
    setMostrarSensores(false)
    setMostrarVehiculos(false)
    setMostrarAlertas(false)
    setMostrarSobre(false)
    setMostrarTrabajadores(true)
  }

  function onClickVehiculos() {
    setMostrarSensores(false)
    setMostrarTrabajadores(false)
    setMostrarAlertas(false)
    setMostrarSobre(false)
    setMostrarVehiculos(true)
  }

  function onClickAlertas() {
    setMostrarSensores(false)
    setMostrarTrabajadores(false)
    setMostrarVehiculos(false)
    setMostrarSobre(false)
    setMostrarAlertas(true)
  }

  if (user !== null && user.admin === true && user.activo === true) {
    return (
      <>            
        <div className="bgpurple">
          <div className="container-xl bgwhite p-0">
            <Header />
            <div className="px-4 pb-5 pt-4">
              <div className="row">
                <div className="col-xl-3 col-md-6 mb-3">
                    <div className="card text-white bg-primary o-hidden h-100">
                        <div className="card-body">
                          <div className="mr-5">Sensores BLE</div>
                        </div>
                        <div className="card-footer text-white clearfix small z-1" onClick={onClickSensores}>
                          <span className="float-left">Ver detalles</span>
                          <span className="float-right">
                            <i className="fas fa-angle-right"></i>
                          </span>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                    <div className="card text-white bg-warning o-hidden h-100">
                        <div className="card-body">
                            <div className="mr-5">Trabajadores</div>
                        </div>
                        <div className="card-footer text-white clearfix small z-1" onClick={onClickTrabajadores}>
                          <span className="float-left">Ver detalles</span>
                          <span className="float-right">
                            <i className="fas fa-angle-right"></i>
                          </span>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                    <div className="card text-white bg-success o-hidden h-100">
                        <div className="card-body">
                            <div className="mr-5">Vehículos</div>
                        </div>
                        <div className="card-footer text-white clearfix small z-1" onClick={onClickVehiculos}>
                          <span className="float-left">Ver detalles</span>
                          <span className="float-right">
                            <i className="fas fa-angle-right"></i>
                          </span>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                    <div className="card text-white bg-danger o-hidden h-100">
                        <div className="card-body">
                            <div className="mr-5">Alertas</div>
                        </div>
                        <div className="card-footer text-white clearfix small z-1" onClick={onClickAlertas}>
                          <span className="float-left">Ver detalles</span>
                          <span className="float-right">
                            <i className="fas fa-angle-right"></i>
                          </span>
                        </div>
                    </div>
                </div>
              </div>
              <ListaSensores mostrar={mostrarSensores}/>
              <ListaTrabajadores mostrar={mostrarTrabajadores} />
              <ListaVehiculos mostrar={mostrarVehiculos} />
              <ListaAlertas mostrar={mostrarAlertas} />

              <PerimeterIndoor mostrar={mostrarSobre} />
            </div>
            <Footer></Footer>
          </div>
        </div>
      </>
    );
  } else return <></>
}
