import React from 'react'
import { useState, useEffect } from 'react'
import { getHorarios, updateSensor, auth } from '../../firebase/firebase'
import { ModalEditarSensor } from './ModalEditarSensor'
import { UseModalEditarSensor } from './UseModalEditarSensor'
import { sendPasswordResetEmail } from "firebase/auth";
import { Button, Table } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { Input } from 'reactstrap'

export function ModalsEditarSensor({sensor, onChangeSensor}) {
    const navigate = useNavigate();
    const [isOpenModal, openModal, closeModal] = UseModalEditarSensor(false)

    const [uuid, setUuid] = useState(sensor.uuid)
    const [major, setMajor] = useState(sensor.major)
    const [minor, setMinor] = useState(sensor.minor)
    const [x, setX] = useState(sensor.x)
    const [y, setY] = useState(sensor.y)
    const [tx, setTx] = useState(sensor.TX)

    async function onClickAceptar() {
        sensor.uuid = uuid
        sensor.major = major
        sensor.minor = minor
        sensor.x = x
        sensor.y = y
        sensor.TX = tx
        await updateSensor(sensor)
        
        onChangeSensor()
        closeModal()
    }

    return (
        <>
            <style type="text/css">
                {`
                    .btn-blue {
                        background-color: blue;
                        color: white;
                    }
                    .btn-red {
                        background-color: red;
                        color: white;
                    }
                `}
            </style>
            <Button className="btn-primary btn-block" onClick={openModal}>Editar sensor</Button>
            <ModalEditarSensor isOpen={isOpenModal} closeModal={closeModal}>
                <h2><b>Editar Sensor</b></h2>
                <hr />
                <h3 className="mb-3">Datos del Sensor</h3>
                <Table>
                    <tbody>
                        <tr>
                            <td className='p-1'><label>UUID</label></td>
                            <td className='p-0'><Input defaultValue={uuid} onChange={(e) => {setUuid(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Major</label></td>
                            <td className='p-0'><Input defaultValue={major} onChange={(e) => {setMajor(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Minor</label></td>
                            <td className='p-0'><Input defaultValue={minor} onChange={(e) => {setMinor(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>TX</label></td>
                            <td className='p-0'><Input defaultValue={tx} onChange={(e) => {setTx(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Cord X</label></td>
                            <td className='p-0'><Input defaultValue={x} onChange={(e) => {setX(e.target.value)}} /></td>
                        </tr>
                        <tr>
                            <td className='p-1'><label>Cord Y</label></td>
                            <td className='p-0'><Input defaultValue={y} onChange={(e) => {setY(e.target.value)}} /></td>
                        </tr>
                    </tbody>
                </Table>
                
                <br />
                <div className='text-right'>
                    <Button className="btn-danger mr-1" onClick={closeModal}>Cancelar</Button>
                    <Button onClick={onClickAceptar}>Aceptar</Button>
                </div>
                
            </ModalEditarSensor>
        </>
    )
}
