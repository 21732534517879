import { useState, useEffect } from 'react'
import { getAlerta } from "../firebase/firebase";
import { Button } from 'react-bootstrap';
import "../mycss.css"
// import { ModalsEditarTrabajador } from './modalEditarTrabajador/ModalsEditarTrabajador';

export function Alerta({alertaAux}) {
  const [alerta, setAlerta] = useState(alertaAux)

  async function onChangeAlerta() {
    const alertaAux = await getAlerta(alerta)
    setAlerta(alertaAux)
  }

  return (
  <>
    <tr className="alturaColumna p-0">
      <td className="p-3">{alerta.fecha}</td>
      <td className="p-3">{alerta.hora}</td>
      <td className="p-3">{alerta.distancia} m</td>
      <td className="p-3">{alerta.trabajador}</td>
      <td className="p-3">{alerta.vehiculo}</td>
    </tr>
  </>
  );
}