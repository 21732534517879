import { useState, useEffect } from 'react'
// import { ModalsEditarTrabajador } from "./modalEditarTrabajador/ModalsEditarTrabajador";
import { getSensor } from "../firebase/firebase";
import { Button } from 'react-bootstrap';
import "../mycss.css"
import { ModalsEditarSensor } from './modalEditarSensor/ModalsEditarSensor';

export function Sensor({sensorAux}) {
  const [sensor, setSensor] = useState(sensorAux)

  async function onChangeSensor() {
    const sensorAux = await getSensor(sensor)
    setSensor(sensorAux)
  }

    // async function onClickDesactivar() {
    //     sensor.estado = false
    //     await updateSensor(sensor)
    //     setState(false)
    // }
  
    // async function onClickActivar() {
    //     sensor.estado = true
    //     await updateSensor(sensor)
    //     setState(true)
    // }

    return (
    <>
    <tr className="alturaColumna p-0">
        <td className="p-3">{sensor.uuid}</td>
        <td className="p-3">{sensor.major}</td>
        <td className="p-3">{sensor.minor}</td>
        <td className="p-3">{sensor.TX}</td>
        <td className="p-3">{sensor.x}</td>
        <td className="p-3">{sensor.y}</td>
        <td className="anchoCelda p-1">
        {/* <div className='row'> */}
            <div><ModalsEditarSensor sensor={sensor} onChangeSensor={onChangeSensor} /></div>
            {/* <div className='col'><ModalsBajaVacaciones trabajador={trabajador}/></div> */}
        {/* </div>
        <div className='row'> */}
            {/* <div classNamke='col'><ModalsEditarTrabajador trabajador={trabajador}/></div> */}
            {/* <div className='col'><Button className='btn-danger btn-block alturaBotones p-0' onClick={onClickDesactivar}>Desactivar</Button></div> */}
        {/* </div> */}
        </td>
    </tr>
    </>
    );
}
  