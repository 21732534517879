import { useState, useEffect } from 'react'
import { getTrabajador } from "../firebase/firebase";
import { Button } from 'react-bootstrap';
import "../mycss.css"
import { ModalsEditarTrabajador } from './modalEditarTrabajador/ModalsEditarTrabajador';

export function Trabajador({trabajadorAux}) {
  const [trabajador, setTrabajador] = useState(trabajadorAux)

  async function onChangeTrabajador() {
    const trabajadorAux = await getTrabajador(trabajador)
    setTrabajador(trabajadorAux)
  }

  return (
  <>
    <tr className="alturaColumna p-0">
      <td className="p-3">{trabajador.nombre}&nbsp;{trabajador.apellidos}</td>
      <td className="p-3">{trabajador.email}</td>
      <td className="anchoCelda p-1">
        {/* <div className='row'> */}
          <div><ModalsEditarTrabajador trabajador={trabajador} onChangeTrabajador={onChangeTrabajador} /></div>
          {/* <div className='col'><ModalsBajaVacaciones trabajador={trabajador}/></div> */}
        {/* </div>
        <div className='row'> */}
          {/* <div classNamke='col'><ModalsEditarTrabajador trabajador={trabajador}/></div> */}
          {/* <div className='col'><Button className='btn-danger btn-block alturaBotones p-0' onClick={onClickDesactivar}>Desactivar</Button></div> */}
        {/* </div> */}
      </td>
    </tr>
  </>
  );
}