import { Button, Table } from 'react-bootstrap';
import "../mycss.css"
import { Trabajador } from "./Trabajador"

export function WrapperTrabajadores({ trabajadores, currentPage, prevHandler, nextHandler, mostrarTrabajador, paginasTotales }) {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th className="anchoCelda">Acciones</th>
            </tr>
          </thead>
          <tbody>
          {trabajadores.map((trabajador) => (
            <Trabajador key={`${trabajador.uuid}`} trabajadorAux={trabajador} mostrarTrabajador={mostrarTrabajador}/>
          ))}
          </tbody>
        </Table>
        <div className="text-center pt-2">
            <Button onClick={prevHandler}>Anterior</Button>
            <span className="px-4">Pagina {currentPage} de {paginasTotales}</span>
            <Button onClick={nextHandler}>Siguiente</Button>
        </div>
      </>
    );
}
