import logo from "../img/logo_perimeter_indoor_grande_2.png"
import logoCLM from "../img/logoCLM.png"
import logoUE from "../img/logoUE.png"

export function PerimeterIndoor({mostrar}) {

  if (mostrar === true) {
    return(
    <>
        <div className="text-center">
            <img src={logo} alt="logo" />
        </div>
        <div className="text-justify mx-5">
            <p><b>Proyecto incentivado con una subvención cofinanciada en un 80% por el Fondo Europeo de Desarrollo Regional, en el marco del Programa INNOVA ADELANTE en Castilla-La Mancha en su convocatoria 2022.</b></p>

            <p>Título del proyecto: <b>PERIMETER INDOOR</b></p>

            <p>Expediente: <b>45/22/IN/031</b></p>

            <p>Descripción del Proyecto</p>

            <p>El objetivo general del proyecto es desarrollar un sistema de detección de personas y movimiento indoor que permita mejorar la seguridad de los trabajadores en torno a vehículos o maquinaria de todo tipo que se encuentra en entornos cerrados, instalaciones como naves, almacenes de logística, etc., mediante el uso de tecnologías innovadoras basadas en bluetooth y en BLE.</p>

            <p>Este sistema permitirá la detección de personas en entornos de trabajo con maquinaria en movimiento (como carretillas, toritos, elevadoras, furgonetas, etc.) en el interior de naves industriales, siendo mínimamente invasivo en las personas, de fácil instalación y gestión y buscando un precio reducido y asequible para pequeñas y medianas empresas.</p>
        </div>
        <div className="row mt-5">
            <div className="col-xl-6 text-center"><img src={logoCLM} height={150} width={240} alt="Logo Castilla La Mancha" /></div>
            <div className="col-xl-6 text-center"><img src={logoUE} height={150} width={500} alt="Logo Unión Europea" /></div>
        </div>
    </>)
  }
  else return <></>
}